import React, { useEffect, useState, useContext } from "react";
import { Post } from "../types/object";
import axios from "axios";
import PostModal from "../components/PostModal";
import { useAppSelector, useAppDispatch } from "../hooks";
import { getUserById } from "../store/user/userSlice";
import { AuthContext } from "../context/auth";
import DraftCard from "../components/Card/DraftCard";

const Draft = () => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { userToken } = useContext(AuthContext);

  const [drafts, setDrafts] = useState<Post[]>([]);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [draftToEdit, setDraftToEdit] = useState<Post>();

  useEffect(() => {
    if (!user && userToken) {
      dispatch(getUserById(userToken));
    }
  }, [user, dispatch]);

  useEffect(() => {
    const initLoading = async () => {
      await fetchPosts();
    };
    if (userToken) {
      initLoading();
    }
  }, [userToken]);

  const fetchPosts = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/draft/get/${userToken}`;
      const {
        data: { result },
      } = await axios.get(apiUrl);

      setDrafts(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickEdit = (data: Post) => {
    setDraftToEdit(data);
    setOpenEditModal(true);
  };

  const handlePublish = async (editedData: Post) => {
    try {
      const { id, title, content, user, tag, draft } = editedData;
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/update`;
      const {
        data: { result },
      } = await axios.post(apiUrl, { id, title, content, tag, userId: user?.id, draft });
      if (result.draft) {
        setDrafts([result, ...drafts.filter((draft) => draft.id !== result.id)]);
      } else {
        setDrafts([...drafts.filter((draft) => draft.id !== result.id)]);
      }

      setOpenEditModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container mx-auto px-8 mt-12">
        <div className="flex gap-6">
          <div className="w-full">
            {drafts && drafts.length > 0 ? (
              drafts?.map((draft, index) => {
                return <DraftCard post={draft} key={index} onEditAction={handleClickEdit} />;
              })
            ) : (
              <p className="text-center mt-6 text-3xl font-semibold">There is no data!</p>
            )}
          </div>
        </div>

        {draftToEdit && (
          <PostModal
            open={openEditModal}
            modalTitle="Edit a draft"
            modalType="draft"
            onClose={() => {
              setOpenEditModal(false);
              setDraftToEdit(undefined);
            }}
            post={draftToEdit}
            onSave={handlePublish}
          />
        )}
      </div>
    </>
  );
};

export default Draft;
