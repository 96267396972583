import React, { FC } from "react";
import { User } from "../../types/object";
import ProfileEmptyIcon from "../../assets/profile/user_empty.png";

type RoleTypeProps = {
  authors?: User[];
  onEditRole: (author?: User) => void;
};

const RoleTable: FC<RoleTypeProps> = ({ authors, onEditRole }) => {
  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="p-4">
          {/* <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <AiOutlineSearch />
            </div>
            <input
              type="text"
              id="table-search"
              className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg w-80 pl-10 p-2.5 focus:outline-none"
              placeholder="Search for items"
            />
          </div> */}
        </div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              {/* <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded"
                  />
                </div>
              </th> */}
              <th scope="col" className="px-6 py-3">
                Avatar
              </th>
              <th scope="col" className="px-6 py-3">
                Nickname
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {authors?.map((author, index) => {
              return (
                <tr className="bg-white border-b hover:bg-gray-50" key={index}>
                  {/* <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input type="checkbox" className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded" />
                    </div>
                  </td> */}
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    <img
                      src={
                        author?.avatar
                          ? author.avatar.includes("http")
                            ? author.avatar
                            : `${process.env.REACT_APP_SPACES_ENDPOINT}/${author.avatar}`
                          : ProfileEmptyIcon
                      }
                      alt={`avatar${index}`}
                      width={50}
                      height={50}
                      className="rounded-full"
                    />
                  </th>
                  <td className="px-6 py-4">{author?.nickname}</td>
                  <td className="px-6 py-4">{author?.email}</td>
                  <td className="px-6 py-4">{author?.role}</td>
                  <td className="px-6 py-4 text-right">
                    <button type="button" className="font-medium hover:underline" onClick={() => onEditRole(author)}>
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoleTable;
