import React, { useEffect, useState, useContext } from "react";
import PostCard from "../components/Card/PostCard";
import { Comment, Post } from "../types/object";
import axios from "axios";
import PostModal from "../components/PostModal";
import { useAppSelector, useAppDispatch } from "../hooks";
import { getUserById } from "../store/user/userSlice";
import { AuthContext } from "../context/auth";
import TagCard from "../components/Card/TagCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingSpinner from "../components/LoadingSpinner";
import { getTrackingUserId } from "../store/comment/trackingSlice";

const HomePage = () => {
  const { user } = useAppSelector((state) => state.user);
  const { tracking } = useAppSelector((state) => state.tracking);
  const dispatch = useAppDispatch();

  const { userToken } = useContext(AuthContext);

  const [posts, setPosts] = useState<Post[]>([]);

  const [selectedTag, setSelectedTag] = useState<string>();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [postToEdit, setPostToEdit] = useState<Post>();

  //infinite state
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [infiniteError, setInfiniteError] = useState<Error>();

  useEffect(() => {
    if (!user && userToken) {
      dispatch(getUserById(userToken));
    }
  }, [user, dispatch]);

  useEffect(() => {
    const initLoading = async () => {
      await fetchPosts();
    };
    if (selectedTag) {
      initLoading();
    }
  }, [selectedTag]);

  useEffect(() => {
    if (!tracking && userToken) {
      dispatch(getTrackingUserId(userToken));
    }
  }, [tracking, dispatch]);

  const fetchPosts = async () => {
    setInfiniteLoading(true);
    try {
      const apiUrl =
        selectedTag && selectedTag.length > 0
          ? `${process.env.REACT_APP_SERVER_API}/post/getAll?offset=${posts.length}&tag=${selectedTag}`
          : `${process.env.REACT_APP_SERVER_API}/post/getAll?offset=${posts.length}`;

      setTimeout(async () => {
        const {
          data: { result },
        } = await axios.get(apiUrl);
        if (result.length <= 0) {
          setHasNextPage(false);
        }

        setPosts([...posts, ...result]);
        setInfiniteLoading(false);
      }, 800);
    } catch (error) {
      console.log(error);
      setInfiniteLoading(false);
    }
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: infiniteLoading,
    hasNextPage,
    onLoadMore: fetchPosts,
    disabled: !!infiniteError,
    rootMargin: "0px 0px 0px 0px",
  });

  const handleClickEdit = (data: Post) => {
    setPostToEdit(data);
    setOpenEditModal(true);
  };

  const handleEdit = async (editedData: Post) => {
    try {
      const { id, title, content, user, tag } = editedData;
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/update`;
      const {
        data: { result },
      } = await axios.post(apiUrl, { id, title, content, tag, userId: user?.id });
      setPosts([result, ...posts.filter((post) => post.id !== result.id)]);
      setOpenEditModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickTag = (tag: string) => {
    if (tag === selectedTag) {
      return;
    }
    if (tag) {
      setSelectedTag(tag);
      setPosts([]);
    }
  };

  const handleClickHidden = async (value: Post) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/change/status`;
      const {
        data: { result },
      } = await axios.post(apiUrl, { id: value.id, hiddenStatus: !value.hiddenStatus, userId: value.userId });
      const newPosts = posts.map((post) => {
        if (post.id === result.id) {
          return { ...post, hiddenStatus: result.hiddenStatus };
        } else {
          return post;
        }
      });
      setPosts(newPosts);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditComment = async (dataToEdit: Comment) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/comment/update`;
      const {
        data: { result },
      } = await axios.post(apiUrl, dataToEdit);

      const newPosts = posts.map((post) => {
        if (post.id === result.id) {
          return { ...post, comments: result.comments };
        } else {
          return post;
        }
      });
      setPosts(newPosts);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateComment = async (dataToCreate: Comment) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/comment/create`;
      const {
        data: { result },
      } = await axios.post(apiUrl, dataToCreate);

      const newPosts = posts.map((post) => {
        if (post.id === result.id) {
          return { ...post, comments: result.comments };
        } else {
          return post;
        }
      });
      setPosts(newPosts);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDelete = async (postId: number) => {
    try {
      if (window.confirm("Are you sure you want to delete?") === true) {
        const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/delete/${postId}`;
        await axios.get(apiUrl);
        const newPosts = posts.filter((post) => post.id !== postId);
        setPosts(newPosts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container mx-auto px-8 mt-12">
        <div className="flex gap-6">
          <div className="w-2/5">
            <TagCard onSearchTag={handleClickTag} selectedTag={selectedTag} />
          </div>
          <div className="w-full overflow-auto">
            {posts && posts.length > 0 ? (
              posts
                ?.sort(
                  (a, b) =>
                    new Date(b.comments?.[0]?.date! ?? b.date).getTime() -
                    new Date(a.comments?.[0]?.date! ?? a.date).getTime()
                )
                .map((post, index) => {
                  return (
                    <PostCard
                      post={post}
                      key={index}
                      onEditAction={handleClickEdit}
                      onDeletePostAction={handleClickDelete}
                      onHidden={handleClickHidden}
                      onCreateComment={handleCreateComment}
                      onEditCommentAction={handleEditComment}
                      onSearchTag={handleClickTag}
                    />
                  );
                })
            ) : (
              <>
                {!hasNextPage && !infiniteLoading && (
                  <p className="text-center mt-6 text-3xl font-semibold">There is no data!</p>
                )}
              </>
            )}
          </div>
        </div>

        {postToEdit && (
          <PostModal
            open={openEditModal}
            modalTitle="Edit a post"
            modalType="edit"
            onClose={() => {
              setOpenEditModal(false);
              setPostToEdit(undefined);
            }}
            post={postToEdit}
            onSave={handleEdit}
          />
        )}
      </div>

      {(infiniteLoading || hasNextPage) && (
        <div ref={sentryRef} className="flex justify-end w-2/3 my-12 font-bold text-gray-600 text-2xl text-center">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default HomePage;
