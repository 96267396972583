import React, { FC, useState, useEffect } from "react";
import Input from "../Input/Input";

type TagCardProps = {
  onSearchTag: (text: string) => void;
  selectedTag?: string;
};
const TagCard: FC<TagCardProps> = ({ onSearchTag, selectedTag }) => {
  const [tagText, setTagText] = useState<string | undefined>(selectedTag);
  useEffect(() => {
    if (selectedTag) {
      setTagText(selectedTag);
    }
  }, [selectedTag]);

  return (
    <div className="mt-5 flex flex-col my-6 p-6 bg-white rounded-xl shadow-sm hover:drop-shadow-lg border">
      <p className="font-semibold text-lg text-gray-600">Tag</p>
      <Input
        type="text"
        required
        placeholder="Enter a tag"
        value={tagText ?? ""}
        onChange={(e: any) => setTagText(e.target.value)}
      />
      <div className="flex items-center justify-between mt-4">
        <button
          type="button"
          className="big-btn font-bold rounded-full dhq-gradient w-24 h-10 whitespace-nowrap"
          onClick={() => {
            if (tagText) {
              onSearchTag(tagText);
            }
          }}
        >
          Search
        </button>
        <button
          className="big-btn font-bold rounded-full dhq-gradient w-24 h-10 whitespace-nowrap"
          onClick={() => {
            if (tagText) {
              setTagText("");
              window.location.href = "/";
            }
          }}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default TagCard;
