import React, { FC, useState } from "react";
import moment from "moment";
import { Post } from "../../types/object";
import ProfileEmptyIcon from "../../assets/profile/user_empty.png";

type PostCardProps = {
  post: Post;
  onEditAction?: (post: Post) => void;
};

const DraftCard: FC<PostCardProps> = ({ post, onEditAction }) => {
  const [isClickSeeMore, setClickSeeMore] = useState(false);

  const { user, date, content, title, hiddenStatus, id: postId, tag } = post;

  return (
    <div className="mt-5 flex flex-col my-6 w-full p-6 min-h-72 bg-white rounded-xl shadow-sm hover:drop-shadow-lg border">
      <div className="flex items-center justify-between  mb-4 text-gray-600">
        <div className="flex items-center gap-4">
          <img
            src={
              user?.avatar
                ? user.avatar.includes("http")
                  ? user.avatar
                  : `${process.env.REACT_APP_SPACES_ENDPOINT}/${user.avatar}`
                : ProfileEmptyIcon
            }
            alt="profile"
            className="rounded-full"
            width={80}
            height={80}
          />

          <div>
            <p className="font-medium">{user?.nickname}</p>
            <p className="text-gray-400">{moment(date).format("MM.DD.YYYY h:mm A")}</p>
            <p className="font-semibold">{hiddenStatus ? title : "Hidden"}</p>
          </div>
        </div>

        <div>
          <button
            className="big-btn font-bold rounded-full dhq-gradient w-28 whitespace-nowrap"
            onClick={() => onEditAction && onEditAction(post)}
          >
            Edit
          </button>
        </div>
      </div>
      <hr />
      <div className="my-4 text-gray-600">
        <p>{isClickSeeMore ? content : content.length > 35 ? `${content.substring(0, 35)}. . .` : content}</p>
        {content.length > 35 && (
          <button
            className="text-gray-700 text-xs font-semibold underline"
            onClick={() => setClickSeeMore(!isClickSeeMore)}
          >
            {isClickSeeMore ? "See less" : "See more"}
          </button>
        )}
      </div>
      <hr />

      <div className="mt-8 flex">
        {tag && <p className={`font-bold rounded-full bg-gray-200 px-4 py-1 text-gray-600`}>{tag}</p>}
      </div>
    </div>
  );
};

export default DraftCard;
