import React, { useContext, FC, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import MainLayout from "./components/Layout/MainLayout";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import AuthProvider, { AuthContext } from "./context/auth";
import UserDetail from "./pages/UserDetail";
import Reset from "./pages/Reset";
import Draft from "./pages/Draft";
import NotFound from "./pages/NotFound";

type RouteProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

const PrivateRoute: FC<RouteProps> = ({ children }) => {
  const { userToken } = useContext(AuthContext);
  const pathName = window.location.pathname;
  const navigate = useNavigate();
  useEffect(() => {
    if (userToken && (pathName.includes("login") || pathName.includes("signup") || pathName.includes("reset"))) {
      navigate("/", { replace: true });
    }
    if (!userToken && !(pathName.includes("login") || pathName.includes("signup") || pathName.includes("reset"))) {
      navigate("/", { replace: true });
    }
  }, [userToken]);

  return (
    <>
      <MainLayout>
        <Routes>{children}</Routes>
      </MainLayout>
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <PrivateRoute>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/p/:authorId" element={<UserDetail />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/draft" element={<Draft />} />
          <Route path="*" element={<NotFound />} />
        </PrivateRoute>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
