import React, { FC, useState, useEffect } from "react";
import { User } from "../../types/object";
import BaseModal, { BaseModalProps } from "../BaseModal";

type RoleModalProps = {
  onSave: (selectedRole: string, authorId: number) => void;
  selectedAuthor?: User;
} & BaseModalProps;

const RoleModal: FC<RoleModalProps> = ({ open, onClose, onSave, selectedAuthor }) => {
  const [selectedRole, setRole] = useState<string>();
  useEffect(() => {
    setRole(selectedAuthor?.role);
  }, [selectedAuthor]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Select a role"
      footerActions={[
        { type: "close", action: () => onClose() },
        {
          type: "update",
          action: () => selectedRole && selectedAuthor?.id && onSave(selectedRole, selectedAuthor?.id),
        },
      ]}
    >
      <div>
        <div className="flex items-center mb-4">
          <input
            id="admin"
            type="radio"
            value="ADMIN"
            name="role"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
            checked={selectedRole?.toLocaleLowerCase() === "admin"}
            onChange={(e) => setRole(e.target.value)}
          />
          <label htmlFor="admin" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Admin
          </label>
        </div>
        <div className="flex items-center mb-4">
          <input
            id="moderator"
            type="radio"
            value="MODERATOR"
            name="role"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
            checked={selectedRole?.toLocaleLowerCase() === "moderator"}
            onChange={(e) => setRole(e.target.value)}
          />
          <label htmlFor="moderator" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Moderator
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="user"
            type="radio"
            value="USER"
            name="role"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
            checked={selectedRole?.toLocaleLowerCase() === "user"}
            onChange={(e) => setRole(e.target.value)}
          />
          <label htmlFor="user" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            User
          </label>
        </div>
      </div>
    </BaseModal>
  );
};

export default RoleModal;
