import React, { FC } from "react";
import TopBar from "./TopBar";

type MainLayoutProps = {} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <div>
      <TopBar />
      {children}
    </div>
  );
};

export default MainLayout;
