import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiLock } from "react-icons/fi";
import { AuthContext } from "../context/auth";
import Button from "../components/Button/Button";
import { ButtonTypes } from "../components/Button/Button";
import Input from "../components/Input/Input";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SignUpInputs } from "../types/object";
import { useAppDispatch, useAppSelector } from "../hooks";
import { loginUser, signUpUser } from "../store/user/userSlice";
// import { GoogleLogin } from "react-google-login";

const schema = yup.object({
  email: yup.string().required(),
  password: yup.string().required("Password is required").min(8, "Password length should be at least 8 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must and should match"),
});

const SignUp = () => {
  const { authenticate, userToken } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpInputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (user) {
      authenticate(user?.id);
      navigate("/", { replace: true });
    }
  }, [user]);

  const onSubmit = async (info: SignUpInputs) => {
    try {
      const { email, password } = info;
      const nickname = email.split("@")[0];
      const userInfo = { nickname, email, password };
      await dispatch(signUpUser({ user: userInfo }));
    } catch (error) {
      console.log(error);
    }
  };

  // const responseGoogle = async (res: any) => {
  //   try {
  //     const { name, imageUrl, email } = res.profileObj;
  //     await dispatch(loginUser({ user: { nickname: name, avatar: imageUrl, email } }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      {!userToken && (
        <div className="container mx-auto px-8">
          <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <div>
                <img
                  className="mx-auto h-12 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                  alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                  Enter your email address and password
                </h2>
              </div>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <Input type="email" required placeholder="Email address" {...register("email")} />
                </div>

                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <Input type="password" required placeholder="Password" {...register("password")} />
                  <p className="text-sm text-red-500">{errors.password?.message}</p>
                </div>

                <div>
                  <label htmlFor="confirm-password" className="sr-only">
                    Confirm password
                  </label>
                  <Input type="password" required placeholder="Confirm password" {...register("confirmPassword")} />
                  <p className="text-sm text-red-500">{errors.confirmPassword?.message}</p>
                </div>

                {/* <div>
                  <GoogleLogin
                    clientId={process.env.REACT_GOOGLE_CLIENT_ID}
                    buttonText="SignUp with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    className="w-full text-gray-600"
                  />
                </div> */}

                <Button type={ButtonTypes.Submit}>
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <FiLock className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" />
                  </span>
                  Sign up
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
