import React, { FC, useContext, useState, useEffect } from "react";
import moment from "moment";
import { Comment, Post } from "../../types/object";
import ProfileEmptyIcon from "../../assets/profile/user_empty.png";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { BiCommentDetail, BiHide, BiShow } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { saveTracking } from "../../store/comment/trackingSlice";

type PostCardProps = {
  post: Post;
  onEditAction?: (post: Post) => void;
  onDeletePostAction?: (pId: number) => void;
  onEditCommentAction?: (comment: Comment) => void;
  onHidden?: (post: Post) => void;
  onCreateComment?: (comment: Comment) => void;
  onSearchTag?: (tag: string) => void;
};

const PostCard: FC<PostCardProps> = ({
  post,
  onEditAction,
  onDeletePostAction,
  onEditCommentAction,
  onHidden,
  onCreateComment,
  onSearchTag,
}) => {
  const { userToken } = useContext(AuthContext);

  const dispatch = useAppDispatch();
  const { user: currentUser } = useAppSelector((state) => state.user);
  const { tracking } = useAppSelector((state) => state.tracking);

  const navigate = useNavigate();
  const [isClickComment, setClickComment] = useState(false);
  const [inputComment, setInputComment] = useState<string>("");
  const [inputEditingComment, setInputEditingComment] = useState<string>("");
  const [isShownComment, setShownComment] = useState(false);
  const [editClickComment, setEditClickComment] = useState<number>();
  const [isClickSeeMore, setClickSeeMore] = useState(false);

  const renderComments = (comment: Comment, index: number) => {
    const { id, content, user: author, date } = comment;

    return (
      <div className="flex items-center gap-2 mb-4 text-gray-600" key={index}>
        <img
          src={
            author?.avatar
              ? author.avatar.includes("http")
                ? author.avatar
                : `${process.env.REACT_APP_SPACES_ENDPOINT}/${author.avatar}`
              : ProfileEmptyIcon
          }
          alt="profile"
          className="rounded-full"
          width={50}
          height={50}
        />

        <div className="bg-gray-200 p-2 w-full rounded-md">
          <div className="flex items-center justify-between">
            <div>
              <p className="font-medium">{author?.nickname}</p>
              <p className="text-gray-400">{moment(date).format("MM.DD.YYYY h:mm A")}</p>
            </div>
            {currentUser?.id === author?.id && (
              <button
                className="font-bold rounded-full bg-gray-300 px-4 py-1 text-gray-600"
                onClick={() => {
                  if (editClickComment === id) {
                    setEditClickComment(undefined);
                    setInputEditingComment("");
                  } else {
                    setEditClickComment(id);
                    setInputEditingComment(content ?? "");
                  }
                }}
              >
                {editClickComment === id ? <AiOutlineClose size={22} /> : <AiOutlineEdit size={22} />}
              </button>
            )}
          </div>

          {editClickComment === id ? (
            <div className="flex items-center gap-4">
              <input
                className="w-full my-3 py-3 bg-transparent border-b border-gray-300 outline-none text-gray-600 font-normal"
                placeholder="Add a comment..."
                type="text"
                maxLength={380}
                value={inputEditingComment}
                required
                onChange={(e) => {
                  if (e.target.value.length === 380) {
                    return;
                  }
                  setInputEditingComment(e.target.value);
                }}
              />
              <button
                className="big-btn font-bold rounded-full dhq-gradient w-28 h-9 mt-4 whitespace-nowrap"
                onClick={() => {
                  onEditCommentAction &&
                    onEditCommentAction({ id, postId: postId, content: inputEditingComment, userId: currentUser?.id });
                  setEditClickComment(undefined);
                  setInputEditingComment("");
                }}
              >
                Edit
              </button>
            </div>
          ) : (
            <p className="text-gray-600">{content}</p>
          )}
        </div>
      </div>
    );
  };

  const handleClickShowComment = async (postId: number) => {
    try {
      const dataToSave = {
        ...(tracking?.id && { id: tracking.id }),
        path: [...(tracking ? tracking.path : []), postId.toString()],
        counter: tracking?.counter ? tracking?.counter + 1 : 1,
        userId: userToken,
      };
      if (!isShownComment) {
        await dispatch(saveTracking({ tracking: dataToSave }));
      }
      setShownComment(!isShownComment);
      setEditClickComment(undefined);
    } catch (error) {
      console.log(error);
    }
  };

  const { user, comments, date, content, title, hiddenStatus, id: postId, draft, tag } = post;

  if (!hiddenStatus) {
    if (
      currentUser?.role?.toLowerCase() !== "admin" &&
      currentUser?.role?.toLowerCase() !== "moderator" &&
      currentUser?.id !== user?.id
    ) {
      return <></>;
    }
  }

  if (draft) {
    return <></>;
  }

  return (
    <div className="mt-5 flex flex-col my-6 w-full p-6 min-h-72 bg-white rounded-xl shadow-sm hover:drop-shadow-lg border">
      <div className="flex items-center justify-between  mb-4 text-gray-600">
        <div className="flex items-center gap-4">
          <button onClick={() => navigate(`/p/${user?.id}`, { replace: true })}>
            <img
              src={
                user?.avatar
                  ? user.avatar.includes("http")
                    ? user.avatar
                    : `${process.env.REACT_APP_SPACES_ENDPOINT}/${user.avatar}`
                  : ProfileEmptyIcon
              }
              alt="profile"
              className="rounded-full"
              width={80}
              height={80}
            />
          </button>
          <div>
            <p className="font-medium">{user?.nickname}</p>
            <p className="text-gray-400">{moment(date).format("MM.DD.YYYY h:mm A")}</p>
            <p className="font-semibold">{hiddenStatus ? title : "Hidden"}</p>
          </div>
        </div>
        {userToken === user?.id && (
          <div className="flex">
            <button
              className="big-btn font-bold rounded-full dhq-gradient w-28 whitespace-nowrap"
              onClick={() => onEditAction && onEditAction(post)}
            >
              Edit
            </button>

            <button
              className="big-btn font-bold rounded-full dhq-gradient w-28 whitespace-nowrap ml-3"
              onClick={() => onDeletePostAction && onDeletePostAction(post?.id ?? 0)}
            >
              Delete
            </button>
          </div>
        )}
      </div>
      <hr />
      <div className="my-4 text-gray-600">
        <p>{isClickSeeMore ? content : content.length > 35 ? `${content.substring(0, 35)}. . .` : content}</p>
        {content.length > 35 && (
          <button
            className="text-gray-700 text-xs font-semibold underline"
            onClick={() => setClickSeeMore(!isClickSeeMore)}
          >
            {isClickSeeMore ? "See less" : "See more"}
          </button>
        )}
      </div>
      <hr />

      <div className="my-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {userToken && (
              <button
                className="font-bold rounded-full bg-gray-200 px-4 py-1 text-gray-600"
                onClick={() => setClickComment(true)}
              >
                <BiCommentDetail size={22} />
              </button>
            )}

            {(currentUser?.role?.toLowerCase() === "admin" || currentUser?.role?.toLowerCase() === "moderator") && (
              <button
                className="font-bold rounded-full bg-gray-200 px-4 py-1 text-gray-600 ml-4"
                onClick={() => onHidden && onHidden(post)}
              >
                {hiddenStatus ? <BiShow size={22} /> : <BiHide size={22} />}
              </button>
            )}

            {tag && (
              <button
                className={`font-bold rounded-full bg-gray-200 px-4 py-1 text-gray-600 ${
                  (userToken ||
                    currentUser?.role?.toLowerCase() === "admin" ||
                    currentUser?.role?.toLowerCase() === "moderator") &&
                  "ml-4"
                }`}
                onClick={() => onSearchTag && onSearchTag(tag)}
              >
                {tag}
              </button>
            )}
          </div>

          {!userToken ? (
            <Link to={"/login"} className="font-semibold text-gray-600 underline">
              Login or register to see comments
            </Link>
          ) : (
            <button
              className="font-semibold text-gray-600 underline"
              onClick={() => postId && handleClickShowComment(postId)}
            >
              {isShownComment ? "Hide all comments" : "Show all comments"}
            </button>
          )}
        </div>

        {isClickComment && (
          <div className="flex items-center gap-4">
            <input
              className="w-full my-3 py-3 bg-transparent border-b outline-none text-gray-600 font-normal"
              placeholder="Add a comment..."
              type="text"
              maxLength={380}
              value={inputComment}
              required
              onChange={(e) => {
                if (e.target.value.length === 380) {
                  return;
                }
                setInputComment(e.target.value);
              }}
            />
            <button
              type="submit"
              className="big-btn dhq-gradient h-9 w-28 mt-2"
              onClick={() => {
                onCreateComment && onCreateComment({ postId: postId, content: inputComment, userId: currentUser?.id });
                setInputComment("");
              }}
            >
              Add
            </button>
          </div>
        )}
      </div>

      {/* comment part */}
      {userToken && (
        <div className="mt-2">
          {isShownComment &&
            comments?.map((comment, index) => {
              return renderComments(comment, index);
            })}
        </div>
      )}
    </div>
  );
};

export default PostCard;
