import React from "react";

type AuthProps = {
  userToken: number | undefined;
  authenticate: Function;
  signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const getUserToken = () => {
  const token = localStorage.getItem("challenge_token");
  if (token) return +token;
  return undefined;
};

const AuthProvider = (props: any) => {
  const [userToken, makeUserToken] = React.useState(getUserToken());

  function authenticate(userId: number) {
    makeUserToken(userId ?? undefined);
    localStorage.setItem("challenge_token", `${userId}`);
  }

  function signout() {
    makeUserToken(undefined);
    localStorage.removeItem("challenge_token");
  }
  return (
    <AuthContext.Provider
      value={{
        userToken,
        authenticate,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
