import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Post, PublicAuthor, Comment } from "../types/object";
import PostCard from "../components/Card/PostCard";
import ProfileEmptyIcon from "../assets/profile/user_empty.png";
import PostModal from "../components/PostModal";
import { AuthContext } from "../context/auth";

const UserDetail = () => {
  const { userToken } = useContext(AuthContext);
  const { authorId } = useParams();

  const [authorInfo, setAuthorInfo] = useState<PublicAuthor>({ posts: [] });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [postToEdit, setPostToEdit] = useState<Post>();

  useEffect(() => {
    const fetchAuthorById = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_SERVER_API}/user/p/${authorId}`;
        const {
          data: { result },
        } = await axios.get(apiUrl);
        setAuthorInfo(result);
      } catch (error) {
        console.log(error);
      }
    };
    if (userToken) {
      fetchAuthorById();
    }
  }, []);

  const { posts, avatar, nickname, bio, email } = authorInfo;

  const handleClickEdit = (data: Post) => {
    setPostToEdit(data);
    setOpenEditModal(true);
  };

  const handleEdit = async (editedData: Post) => {
    try {
      const { id, title, content, user, tag } = editedData;
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/update`;
      const {
        data: { result },
      } = await axios.post(apiUrl, { id, title, content, tag, userId: user?.id });

      setAuthorInfo({ ...authorInfo, posts: [result, ...posts.filter((post) => post.id !== result.id)] });

      setOpenEditModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickHidden = async (value: Post) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/change/status`;
      const {
        data: { result },
      } = await axios.post(apiUrl, { id: value.id, hiddenStatus: !value.hiddenStatus, userId: value.userId });
      const newPost = posts?.map((post) => {
        if (post.id === result.id) {
          return { ...post, hiddenStatus: result.hiddenStatus };
        } else {
          return post;
        }
      });
      setAuthorInfo({ ...authorInfo, posts: newPost });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateComment = async (dataToCreate: Comment) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/comment/create`;
      const {
        data: { result },
      } = await axios.post(apiUrl, dataToCreate);

      const newPosts = posts.map((post) => {
        if (post.id === result.id) {
          return { ...post, comments: result.comments };
        } else {
          return post;
        }
      });
      setAuthorInfo({ ...authorInfo, posts: newPosts });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditComment = async (dataToEdit: Comment) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/comment/update`;
      const {
        data: { result },
      } = await axios.post(apiUrl, dataToEdit);

      const newPosts = posts.map((post) => {
        if (post.id === result.id) {
          return { ...post, comments: result.comments };
        } else {
          return post;
        }
      });
      setAuthorInfo({ ...authorInfo, posts: newPosts });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDelete = async (postId: number) => {
    try {
      if (window.confirm("Are you sure you want to delete?") === true) {
        const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/delete/${postId}`;
        await axios.get(apiUrl);
        const newPosts = posts.filter((post) => post.id !== postId);
        setAuthorInfo({ ...authorInfo, posts: newPosts });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mx-auto px-8 mt-12">
      <div className="flex gap-12">
        <div className="w-2/5 mt-4">
          <img
            src={
              avatar
                ? avatar.includes("http")
                  ? avatar
                  : `${process.env.REACT_APP_SPACES_ENDPOINT}/${avatar}`
                : ProfileEmptyIcon
            }
            alt="edit avatar"
            className="rounded-full"
            width={128}
            height={128}
          />
          <div className="text-center w-32">
            <p className="text-2xl font-bold mt-4 text-gray-600">{nickname}</p>
            <p className="text-sm text-gray-400">{email}</p>
          </div>

          <p className="text-gray-600 mt-4 text-sm text-justify">{bio}</p>
        </div>
        <div className="w-full">
          {posts && posts.length > 0 ? (
            posts.map((post, index) => {
              return (
                <PostCard
                  post={post}
                  key={index}
                  onEditAction={handleClickEdit}
                  onDeletePostAction={handleClickDelete}
                  onHidden={handleClickHidden}
                  onCreateComment={handleCreateComment}
                  onEditCommentAction={handleEditComment}
                />
              );
            })
          ) : (
            <p className="text-center mt-6 text-3xl font-semibold">There is no data!</p>
          )}
        </div>
      </div>
      {postToEdit && (
        <PostModal
          open={openEditModal}
          modalTitle="Edit a post"
          modalType="edit"
          onClose={() => {
            setOpenEditModal(false);
            setPostToEdit(undefined);
          }}
          post={postToEdit}
          onSave={handleEdit}
        />
      )}
    </div>
  );
};

export default UserDetail;
