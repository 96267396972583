import React, { forwardRef, Ref } from "react";

const Input = (props: any, ref: Ref<HTMLInputElement>) => {
  return (
    <input
      {...props}
      className="appearance-none relative block w-full px-3 py-2 my-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md 
                focus:outline-none focus:z-10 sm:text-sm"
      ref={ref}
    />
  );
};

export default forwardRef<HTMLInputElement, any>(Input);
