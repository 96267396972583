import React, { useState, useContext, useEffect } from "react";
import ProfileEmptyIcon from "../../assets/profile/user_empty.png";
import axios from "axios";
import PostModal from "../PostModal";
import { Post } from "../../types/object";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUserById } from "../../store/user/userSlice";
import { BiUser } from "react-icons/bi";
import { AiOutlineLogin } from "react-icons/ai";
import { RiDraftLine } from "react-icons/ri";

const TopBar = () => {
  const { userToken, signout } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const [openModal, setOpenModal] = useState(false);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (!user && userToken) {
      dispatch(getUserById(userToken));
    }
  }, [user, dispatch]);

  const handleCreatePost = async (value: Post) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/post/create`;
      await axios.post(apiUrl, value);
      window.location.href = "/";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="min-h-full bg-transparent shadow-sm">
      <div className="container mx-auto px-8">
        <div className="flex items-center justify-between py-4">
          <button onClick={() => (window.location.href = "/")} className="text-2xl font-bold text-gray-600">
            ChallengeMy.investments
          </button>

          <div className="flex items-center">
            {userToken ? (
              <button
                className="big-btn font-bold rounded-full dhq-gradient w-40 whitespace-nowrap mx-4"
                onClick={() => setOpenModal(true)}
              >
                Create a post
              </button>
            ) : (
              <>
                <Link to="/login" className="font-semibold text-gray-600 ml-12">
                  Login
                </Link>
                <Link to="/signup" className="font-semibold text-gray-600 mx-8">
                  Join
                </Link>
              </>
            )}

            {userToken && (
              <div
                className="relative"
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                tabIndex={0}
              >
                <button>
                  <img
                    src={
                      user?.avatar
                        ? user.avatar.includes("http")
                          ? user.avatar
                          : `${process.env.REACT_APP_SPACES_ENDPOINT}/${user.avatar}`
                        : ProfileEmptyIcon
                    }
                    alt="me"
                    width={50}
                    height={50}
                    className="rounded-full"
                  />
                </button>
                {isShown && (
                  <ul className="absolute rounded-lg p-2 text-base font-semibold z-50 list-none bg-white border border-gray-100 shadow-sm w-32 right-0 text-left">
                    <li className="w-full whitespace-nowrap bg-transparent text-gray-400 hover:text-gray-900 border-b p-2">
                      <Link to={"/profile"} className="flex">
                        <BiUser size={22} className="mr-2" />
                        Profile
                      </Link>
                    </li>
                    <li className="w-full whitespace-nowrap bg-transparent text-gray-400 hover:text-gray-900 border-b p-2">
                      <Link to={"/draft"} className="flex items-center">
                        <RiDraftLine size={18} className="mr-2" />
                        My draft
                      </Link>
                    </li>
                    <li className="w-full whitespace-nowrap bg-transparent text-gray-400 hover:text-gray-900 p-2">
                      <button
                        className="font-semibold flex items-center"
                        onClick={() => {
                          signout();
                          window.location.href = "/";
                        }}
                      >
                        <AiOutlineLogin size={20} className="mr-2" />
                        Logout
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <PostModal
        open={openModal}
        modalTitle="Create a post"
        onClose={() => {
          setOpenModal(false);
        }}
        onSave={handleCreatePost}
      />
    </div>
  );
};

export default TopBar;
