import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Tracking } from "../../types/object";

export interface TrackingState {
  tracking?: Tracking;
}

const initialState: TrackingState = {
  tracking: undefined,
};

export const saveTracking = createAsyncThunk<Tracking, TrackingState>(
  `comment/save/tracking`,
  async (trackingInfo, { rejectWithValue, dispatch }) => {
    const apiUrl = `${process.env.REACT_APP_SERVER_API}/comment/track/create`;
    try {
      const {
        data: { result },
      } = await axios.post(apiUrl, trackingInfo.tracking);
      dispatch(setTracking(result));
      return result;
    } catch (error) {
      return undefined;
    }
  }
);

export const getTrackingUserId = createAsyncThunk<Tracking, number>(
  `comment/get/tacking`,
  async (userId, { rejectWithValue, dispatch }) => {
    const apiUrl = `${process.env.REACT_APP_SERVER_API}/comment/track/get/${userId}`;
    try {
      const {
        data: { result },
      } = await axios.get(apiUrl);
      dispatch(setTracking(result));
      return result;
    } catch (error) {
      return undefined;
    }
  }
);

export const TrackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    setTracking: (state, action: PayloadAction<Tracking>) => {
      state.tracking = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveTracking.fulfilled, (state, { payload }) => {
      state.tracking = payload;
    });
    builder.addCase(getTrackingUserId.fulfilled, (state, { payload }) => {
      state.tracking = payload;
    });
  },
});

export const { setTracking } = TrackingSlice.actions;

export default TrackingSlice.reducer;
