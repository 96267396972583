import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiLock } from "react-icons/fi";
import Button from "../components/Button/Button";
import { ButtonTypes } from "../components/Button/Button";
import Input from "../components/Input/Input";
import { AuthContext } from "../context/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SignInInputs } from "../types/object";
import { useAppDispatch, useAppSelector } from "../hooks";
import { loginUser } from "../store/user/userSlice";
// import { GoogleLogin } from "react-google-login";

const schema = yup.object({
  email: yup.string().required(),
  password: yup.string().required("Password is required").min(8, "Password length should be at least 8 characters"),
});

const Login = () => {
  const { authenticate, userToken } = useContext(AuthContext);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      authenticate(user?.id);
      navigate("/", { replace: true });
    }
  }, [user]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (info: SignInInputs) => {
    try {
      await dispatch(loginUser({ user: info }));
    } catch (error) {
      alert("Failed to login!");
      console.log(error);
    }
  };

  // const responseGoogle = async (res: any) => {
  //   try {
  //     const { name, imageUrl, email } = res.profileObj;
  //     await dispatch(loginUser({ user: { nickname: name, avatar: imageUrl, email } }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      {!userToken && (
        <div className="container mx-auto px-8">
          <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <div>
                <img
                  className="mx-auto h-12 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                  alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
              </div>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <Input
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Email address"
                    {...register("email")}
                  />
                  <p className="text-sm text-red-500">{errors.email?.message}</p>
                </div>

                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <Input
                    type="password"
                    autoComplete="current-password"
                    required
                    placeholder="Password"
                    {...register("password")}
                  />
                  <p className="text-sm text-red-500">{errors.password?.message}</p>
                </div>

                {/* <div>
                  <GoogleLogin
                    clientId={process.env.REACT_GOOGLE_CLIENT_ID}
                    buttonText="Login with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    className="w-full text-gray-600"
                  />
                </div> */}

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500
                  border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link to={"/reset"} className="font-medium text-indigo-600 hover:text-indigo-500">
                      Forgot your password?
                    </Link>
                  </div>
                </div>

                <div>
                  <Button type={ButtonTypes.Submit}>
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <FiLock className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" />
                    </span>
                    Sign in
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
