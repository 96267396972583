import React, { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BiEdit } from "react-icons/bi";
import { Post } from "../../types/object";
import BaseModal, { BaseModalProps } from "../BaseModal";
import Input from "../Input/Input";
import { PostInputs } from "../../types/object";
import { AuthContext } from "../../context/auth";

type PostModalProps = {
  onSave: (post: Post) => void;
  post?: Post;
  modalTitle: string;
  modalType?: string;
} & BaseModalProps;

const schema = yup.object({
  title: yup.string().required().max(80, "Title length should be maximum 80 characters"),
  content: yup.string().required("Content is required").max(2000, "Content length should be maximum 2000 characters"),
  tag: yup.string(),
});

const PostModal: FC<PostModalProps> = ({ open, onClose, onSave, post, modalTitle }) => {
  const { userToken } = useContext(AuthContext);

  const [isDraft, setDraft] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PostInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: PostInputs) => {
    if (userToken) {
      let finalData = {
        title: data.title,
        content: data.content,
        ...(data.tag && { tag: data?.tag }),
        draft: isDraft,
        userId: +userToken,
      };

      if (post) {
        finalData = { ...post, ...finalData };
      }
      setDraft(false);
      onSave(finalData);
    }
  };

  const footerActions =
    post && !post.draft
      ? [
          { type: "close", action: () => onClose() },
          {
            type: "save",
            action: () => {},
          },
        ]
      : [
          { type: "close", action: () => onClose() },
          {
            type: "draft",
            action: () => {
              setDraft(true);
            },
          },
          {
            type: "save",
            action: () => {
              // setDraft(false);
            },
          },
        ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseModal
        open={open}
        onClose={onClose}
        title={modalTitle}
        TitleIcon={() => <BiEdit size={24} />}
        footerActions={footerActions}
      >
        <div>
          <p className="font-semibold">Title</p>
          <Input
            type="text"
            required
            placeholder="Enter your post title"
            defaultValue={post?.title}
            {...register("title")}
          />
          <p className="text-red-600 text-sm">{errors.title?.message}</p>
        </div>
        <div>
          <p className="my-2 font-semibold">Content</p>
          <textarea
            className="w-full border border-gray-300 p-2 rounded-md focus:outline-none text-gray-600"
            rows={5}
            required
            placeholder="Enter some long form content."
            defaultValue={post?.content}
            {...register("content")}
          ></textarea>
          <p className="text-red-600 text-sm">{errors.content?.message}</p>
        </div>
        <div>
          <p className="font-semibold">Tag</p>
          <Input type="text" placeholder="Enter your tag" defaultValue={post?.tag} {...register("tag")} />
        </div>
      </BaseModal>
    </form>
  );
};

export default PostModal;
