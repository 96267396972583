import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { User } from "../../types/object";

export interface UserState {
  user?: User;
  // authors?: User[];
}

const initialState: UserState = {
  user: undefined,
  // authors: undefined,
};

export const loginUser = createAsyncThunk<User, UserState>(
  `user/loginUser`,
  async (userInfo, { rejectWithValue, dispatch }) => {
    const apiUrl = `${process.env.REACT_APP_SERVER_API}/user/login`;
    try {
      const {
        data: { result },
      } = await axios.post(apiUrl, userInfo.user);
      dispatch(setUser(result));
      return result;
    } catch (error) {
      alert("Failed to login!");
      return undefined;
    }
  }
);

export const getUserById = createAsyncThunk<User, number>(
  `user/currentUser`,
  async (userId, { rejectWithValue, dispatch }) => {
    const apiUrl = `${process.env.REACT_APP_SERVER_API}/user/get/${userId}`;
    try {
      const {
        data: { result },
      } = await axios.get(apiUrl);
      dispatch(setUser(result));
      return result;
    } catch (error) {
      return undefined;
    }
  }
);

export const signUpUser = createAsyncThunk<User, UserState>(
  `user/signUpUser`,
  async (userToCreat, { rejectWithValue, dispatch }) => {
    const apiUrl = `${process.env.REACT_APP_SERVER_API}/user/create`;
    try {
      const {
        data: { result },
      } = await axios.post(apiUrl, userToCreat.user);
      dispatch(setUser(result));
      return result;
    } catch (error) {
      alert("Failed to sign up!");
      return undefined;
    }
  }
);

export const updateUser = createAsyncThunk<User, UserState>(
  `user/update`,
  async (userToUpdate, { rejectWithValue, dispatch }) => {
    const apiUrl = `${process.env.REACT_APP_SERVER_API}/user/update`;
    try {
      const {
        data: { result },
      } = await axios.post(apiUrl, userToUpdate.user);
      dispatch(setUser(result));
      return result;
    } catch (error) {
      return undefined;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    // setAuthors: (state, action: PayloadAction<User[]>) => {
    //   state.authors = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(getUserById.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(signUpUser.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
