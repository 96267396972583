import React, { FC, useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Input from "../components/Input/Input";
import ProfileEmptyIcon from "../assets/profile/user_empty.png";
import { User, userInputs } from "../types/object";
import { useAppSelector, useAppDispatch } from "../hooks";
import { AuthContext } from "../context/auth";
import { getUserById, updateUser } from "../store/user/userSlice";
import RoleTable from "../components/RoleTable";
import axios from "axios";
import RoleModal from "../components/RoleModal";

const schema = yup.object({
  nickname: yup.string(),
  bio: yup.string().max(350, "Biography length should be maximum 350 characters"),
  currentPassword: yup.string(),
  newPassword: yup.string(),
});

const Profile = () => {
  const { userToken } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const [base64File, setBase64File] = useState<string>();
  const [authors, setAuthors] = useState<User[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAuthor, setSelectedAuthor] = useState<User>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<userInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!user && userToken) {
      dispatch(getUserById(userToken));
    }
  }, [user, userToken, dispatch]);

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_SERVER_API}/user/getAll`;
        const {
          data: { result },
        } = await axios.get(apiUrl);
        setAuthors(result);
      } catch (error) {
        console.log(error);
      }
    };

    if (user?.role === "ADMIN") {
      fetchAuthors();
    }
  }, [user?.role]);

  const onSubmit = (data: userInputs) => {
    const { bio, currentPassword, newPassword, nickname } = data;
    if (!bio && !nickname && !base64File) {
      return;
    }
    const finalData = { id: userToken, nickname, password: newPassword, avatar: base64File, bio };
    onUpdateProfile(finalData);
  };

  const onUpdateProfile = async (dataToUpdate: User) => {
    try {
      await dispatch(updateUser({ user: dataToUpdate }));
      alert("Success!");
    } catch (error) {
      alert("Failed!");
      console.log(error);
    }
  };

  const handleChangeImage = (e: any) => {
    const imageDate = e.target.files;
    if (!imageDate || !imageDate.length) {
      return;
    }

    const fileType = imageDate[0].type.split("/")[1];

    if (fileType !== "png" && fileType !== "jpg" && fileType !== "jpeg") {
      alert("Only jpg/jpeg and png files are allowed!");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setBase64File(reader.result as any);
    };
  };

  const handleSaveRole = async (role: string, authorId: number) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVER_API}/user/update`;
      const {
        data: { result },
      } = await axios.post(apiUrl, { id: authorId, role });
      setAuthors([result, ...authors?.filter((obj) => obj.id !== result.id)]);
      setOpenModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mx-auto px-8 mt-12">
      <div className="flex gap-16 p-8 bg-white rounded-xl shadow-sm hover:drop-shadow-lg border">
        <div>
          <button
            onClick={() => {
              const imageButton = document.querySelector("#imageFile") as HTMLButtonElement | null;
              imageButton?.click();
            }}
          >
            <img
              src={
                base64File
                  ? base64File
                  : user?.avatar
                  ? user.avatar.includes("http")
                    ? user.avatar
                    : `${process.env.REACT_APP_SPACES_ENDPOINT}/${user.avatar}`
                  : ProfileEmptyIcon
              }
              alt="edit avatar"
              className="rounded-full"
              width={180}
              height={180}
            />
          </button>
          <input type="file" accept=".jpg,.jpeg,.png" onChange={handleChangeImage} className="hidden" id="imageFile" />
          <button
            className="big-btn font-bold rounded-full dhq-gradient w-full whitespace-nowrap mt-4"
            onClick={() => {
              setBase64File(undefined);
            }}
          >
            Remove
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full">
            <div>
              <Input
                type="text"
                placeholder="Enter your nickname"
                defaultValue={user?.nickname}
                {...register("nickname")}
              />
            </div>
            <div>
              <textarea
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none text-gray-600 mt-4"
                rows={5}
                placeholder="Enter your bio"
                defaultValue={user?.bio}
                {...register("bio")}
              ></textarea>
              <p className="text-red-600 text-sm">{errors.bio?.message}</p>
            </div>

            <div className="my-8">
              <Input type="password" placeholder="Current password" {...register("currentPassword")} />
            </div>
            <div className="my-8">
              <Input type="password" placeholder="New password" {...register("newPassword")} />
            </div>

            <button
              type="submit"
              className="big-btn font-bold rounded-full dhq-gradient w-40 whitespace-nowrap mt-4 float-right"
            >
              Update
            </button>
          </div>
        </form>
      </div>

      {authors && authors.length > 0 && (
        <div className="mt-8 mb-20">
          <RoleTable
            authors={authors}
            onEditRole={(author) => {
              setOpenModal(true);
              setSelectedAuthor(author);
            }}
          />
          <RoleModal
            open={openModal}
            onSave={handleSaveRole}
            onClose={() => {
              setOpenModal(false);
              setSelectedAuthor(undefined);
            }}
            selectedAuthor={selectedAuthor}
          />
        </div>
      )}
    </div>
  );
};

export default Profile;
